import React from "react"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"

import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import ContactForm from "../components/molecules/contactForm"
import Layout from "../components/layout/layout"
import Departments from "../components/contact/departments"

import { useContactData } from "../hooks/useContactData"

const ContactPage = ({ data: previewData }) => {
  const { uid, title, tagline, page_text, departments, bannerData } =
    useContactData(previewData)

  return (
    <Layout bookBannerData={bannerData}>
      <SEO title={uid} />
      <PageHeader title={title} tagline={tagline} text={page_text} />
      <Container>
        <ContactForm
          formType="contact"
          hasFileUpload={false}
          departments={departments
            .filter(dep => dep.email.text.includes("@"))
            .map(department => ({
              name: department.name,
              email: department.email,
            }))}
        />
      </Container>
      <Departments departments={departments} />
    </Layout>
  )
}

export default withPreview(ContactPage)

const Container = styled.div`
  max-width: 600px;
  padding: 0 40px;
  margin: 0 auto;
  display: grid;
  justify-items: center;
`
